
@media (max-width: 1024px) {
    .header{
      padding: 4rem;
    }
    .big-h{
      font-size: 4rem;
    }
    .about{
      max-height: none;
      padding: 4rem 2rem;
    }
    .imgBx, .content {
      width: 100%;
      padding: 1rem;
     }
    .imgBx{
      height: 50%;
    }
     .btn-group .btn{
       margin: 1rem 0;
       display: block;
     }
     .about::before{
       position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 12rem;
    }
    .skills {
      width: 100%;
    }
    .skills h1{
      font-size: 2.6rem;
      margin: 0.4rem 0;
    }
    .skills::before{
      font-size: 10rem;
    }
    .skills .skill .img__container {
      width: 5rem;
      margin: 1rem;
    }
    .all__projects__container{
      padding:0 1rem;
    }
    .text-center{
      box-shadow: none;
    }
    
    .project{
      flex-direction: column-reverse;
      margin: 0;
      margin-bottom: 2.5rem;
      padding: 0.4rem;
    }
    .flex-reverse{
      flex-direction: column-reverse;
    }
    .container .contactInfo {
      transform: none !important;
    }
    section{
      width: 100%;
    }
    
    .container {
        width: 90%;
        min-width: auto;
        margin: 20px;
        box-shadow: 0 20px 50px rgba(0,0,0,0.2);
        } 
        .container .contactInfo {
        top: 0;
        height: 550px;
        position: relative; 
        box-shadow: none;
        
        } 
        .container .contactForm{
        position: relative;
         padding-left: 0;
        margin-left: 0;
        padding: 40px;
        height: 550px;
        box-shadow: none;
          }

          
    }
