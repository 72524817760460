*{
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,h6,a{
  color: #2a3546;
}
p{
  color: #363F59;
  line-height: 1.5;
}
